import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import BsPopover from 'react-bootstrap/lib/Popover';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';

function Popover({
  placement,
  icon,
  children,
  id,
  trigger,
  rootClose,
  closeOnClick,
  containerPadding
}) {
  const overlayRef = useRef();
  const popover = (
    <BsPopover
      id={id}
      onClick={() => {
        if (closeOnClick) {
          overlayRef.current.hide();
        }
      }}>
      {children}
    </BsPopover>
  );
  return (
    <OverlayTrigger
      containerPadding={containerPadding}
      ref={overlayRef}
      trigger={trigger}
      placement={placement}
      overlay={popover}
      rootClose={rootClose}>
      {icon}
    </OverlayTrigger>
  );
}

Popover.defaultProps = {
  rootClose: true,
  closeOnClick: true,
  containerPadding: 0
};

Popover.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  id: PropTypes.string.isRequired, // used for accessibility
  placement: PropTypes.string.isRequired,
  trigger: PropTypes.string.isRequired,
  rootClose: PropTypes.bool,
  closeOnClick: PropTypes.bool,
  containerPadding: PropTypes.number
};

export default Popover;
