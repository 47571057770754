export const WIDTH_VARIANTS = { min: 'min', full: 'full', default: '' };
export const SIZE_VARIANTS = {
  large: 'lg',
  medium: 'md',
  small: 'sm',
  extraSmall: 'xs',
  default: ''
};

export const DEFAULT_VARIANTS = {
  primary: 'primary',
  secondary: 'secondary',
  control: 'control',
  cta: 'cta',
  alert: 'alert',
  growth: 'growth',
  default: ''
};
