import React from 'react';
import PropTypes from 'prop-types';

const AvatarCaptionFirstLine = ({ firstLine, firstLineLink }) => {
  return firstLineLink ? (
    <a href={firstLineLink} className='avatar-card-label'>
      {firstLine}
    </a>
  ) : (
    <div className='avatar-card-label'>{firstLine}</div>
  );
};

AvatarCaptionFirstLine.defaultProps = {
  firstLine: '',
  firstLineLink: ''
};
AvatarCaptionFirstLine.propTypes = {
  firstLine: PropTypes.string,
  firstLineLink: PropTypes.string
};

export default AvatarCaptionFirstLine;
