import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { DisplayNames } from 'Roblox';
import AvatarCaptionTitle from './AvatarCaptionTitle';
import AvatarCaptionFirstLine from './AvatarCaptionFirstLine';
import AvatarCaptionSecondLine from './AvatarCaptionSecondLine';

function constructUsernameLabel(username) {
  return `@${username}`;
}

const AvatarCaption = ({
  name,
  nameLink,
  displayName,
  labelFirstLine,
  labelFirstLineLink,
  labelSecondLine,
  statusLink,
  statusLinkLabel,
  hasMenu
}) => {
  const classNames = ClassNames('avatar-card-caption', {
    'has-menu': hasMenu
  });
  return (
    <div className={classNames}>
      {DisplayNames.Enabled() ? (
        <React.Fragment>
          <AvatarCaptionTitle title={displayName} titleLink={nameLink} />
          <div className='avatar-card-label'>{constructUsernameLabel(name)}</div>
        </React.Fragment>
      ) : (
        <AvatarCaptionTitle title={name} titleLink={nameLink} />
      )}
      <AvatarCaptionFirstLine firstLine={labelFirstLine} firstLineLink={labelFirstLineLink} />
      <AvatarCaptionSecondLine
        secondLine={labelSecondLine}
        status={statusLinkLabel}
        statusLink={statusLink}
      />
    </div>
  );
};

AvatarCaption.defaultProps = {
  name: '',
  nameLink: '',
  displayName: '',
  labelFirstLine: '',
  labelFirstLineLink: '',
  labelSecondLine: '',
  statusLink: '',
  statusLinkLabel: '',
  hasMenu: false
};
AvatarCaption.propTypes = {
  name: PropTypes.string,
  nameLink: PropTypes.string,
  displayName: PropTypes.string,
  labelFirstLine: PropTypes.string,
  labelFirstLineLink: PropTypes.string,
  labelSecondLine: PropTypes.string,
  statusLink: PropTypes.string,
  statusLinkLabel: PropTypes.string,
  hasMenu: PropTypes.bool
};

export default AvatarCaption;
