import React from 'react';
import PropTypes from 'prop-types';
import { Header, Title } from 'react-bootstrap/lib/Modal';
import Button from '../../button/components/ButtonBase';

function RobloxModalHeader({ title, showCloseButton, onClose, ...otherProps }) {
  const modalTitle = React.isValidElement(title) ? <Title as={title} /> : <Title>{title}</Title>;

  const modalClose = showCloseButton ? (
    <Button type='button' className='close' onClick={onClose}>
      <span className='icon-close' />
    </Button>
  ) : null;
  return (
    <Header {...otherProps} onHide={onClose}>
      {modalClose}
      {modalTitle}
    </Header>
  );
}

RobloxModalHeader.defaultProps = {
  title: '',
  showCloseButton: true,
  onClose: null
};

RobloxModalHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  showCloseButton: PropTypes.bool,
  onClose: PropTypes.func
};

export default RobloxModalHeader;
